
import { defineComponent } from 'vue'
import { uniqBy } from 'lodash'
import { ElMessage, ElLoading, ElMessageBox } from 'element-plus'
import dayjs from 'dayjs'
import searchComponent from '@/components/search-component/index.vue'
import tableComponent from '@/components/table-component/index.vue'
import store from '@/store/index'
import axios from 'axios'
import { getCompanyList } from '@/api/long-insure'

import batchPay from '@/components/batch-pay.vue'
import dialogApproval from './components/dialog-approval.vue'
import {
    payrollRecord,
    payScreenType
} from '@/api/pay'
import {
    stopPay,
    batchApplyEle,
    batchDownloadEle,
    applyEle
    , payrollRecordEle, downloadPayrollRecordEle
} from '@/api/operation.ts'
import { exportProcessDetailOrderNew, exportProcessOrder, payrollRecordExport } from '@/api/export'

import user from '@/utils/user'
import { dateFormat, closewin, downLoadFile } from '@/utils/util'

import { statusOptions } from './options'
const firstDay = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
const lastDay = dayjs().endOf('month').format('YYYY-MM-DD')

export default defineComponent({
    name: 'SalaryAll',
    components: {
        searchComponent,
        tableComponent,
        batchPay,
        dialogApproval
    },
    data() {
        return {
            searchList: {
                searchText: {
                    name: '搜索员工',
                    prop: 'searchText',
                    type: 'input',
                    placeholder: '请输入员工名称',
                    value: '',
                    defaultValue: ''
                },
                serveCompanyName: {
                    name: '所属企业',
                    prop: 'serveCompanyId',
                    type: 'select',
                    filterable: true,
                    placeholder: '请输入派遣单位',
                    value: '',
                    defaultValue: '',
                    options: [],
                    defaultAttrs: {
                        label: 'serveCompanyName',
                        value: 'id'
                    },
                    handleFocus: async (item: any) => {
                        if (item.options?.length) return
                        const result = await getCompanyList()
                        item.options = result.list
                    }
                },
                projectName: {
                    name: '所属项目',
                    prop: 'projectName',
                    type: 'input',
                    placeholder: '请输入项目名称',
                    value: '',
                    defaultValue: ''
                },
                auditStatus: {
                    name: '审核状态',
                    prop: 'auditStatus',
                    type: 'select',
                    placeholder: '请选择',
                    value: '',
                    defaultValue: '',
                    options: [
                        {
                            dictLabel: '审核通过',
                            dictValue: 1
                        },
                        {
                            dictLabel: '审核中',
                            dictValue: 0
                        },
                        {
                            dictLabel: '审核失败',
                            dictValue: 2
                        }
                    ],
                    defaultAttrs: {
                        label: 'dictLabel',
                        value: 'dictValue'
                    },
                    handleFocus: async (item: any) => {
                        // if (item.options?.length) return
                        // const res = await payScreenType('saas-pay-get-status', { loading: true })
                        // item.options = res || []
                        // const all = item.options.find((i: any) => i.dictLabel === '全部')
                        // if (all) {
                        //   all.dictValue = ''
                        // }
                    }
                },
                status: {
                    name: '领薪状态',
                    prop: 'status',
                    type: 'select',
                    placeholder: '请选择',
                    value: '',
                    defaultValue: '',
                    options: [],
                    defaultAttrs: {
                        label: 'dictLabel',
                        value: 'dictValue'
                    },
                    handleFocus: async (item: any) => {
                        if (item.options?.length) return
                        const res = await payScreenType('saas-pay-get-status', { loading: true })
                        item.options = res || []
                        const all = item.options.find((i: any) => i.dictLabel === '全部')
                        if (all) {
                            all.dictValue = ''
                        }
                    }
                },
                getSalaryTime: {
                    name: '领薪时间',
                    prop: 'GetSalaryTime',
                    type: 'dateRange',
                    startPlaceholder: '开始时间',
                    endPlaceholder: '结束时间',
                    value: [],
                    handleChange: (item: any) => {
                        if (item.value?.length) {
                            const startTime = new Date(item.value[0]).getTime()
                            const endTime = new Date(item.value[1]).getTime()
                            const startMonth = dayjs(startTime).month()
                            const endMonth = dayjs(endTime).month()
                            const limitDay = (startMonth + 1 === 7 && endMonth + 1 === 8) ? 61 : 60
                            if (endTime - startTime > limitDay * 24 * 60 * 60 * 1000) {
                                ElMessage({
                                    message: '最多只能选择61天',
                                    type: 'warning'
                                })
                                item.value = []
                            }
                        }
                    }
                },
                payTime: {
                    name: '发薪时间',
                    prop: 'PayTime',
                    type: 'dateRange',
                    startPlaceholder: '开始时间',
                    endPlaceholder: '结束时间',
                    value: [],
                    handleChange: (item: any) => {
                        if (item.value?.length) {
                            const startTime = new Date(item.value[0]).getTime()
                            const endTime = new Date(item.value[1]).getTime()
                            const startMonth = dayjs(startTime).month()
                            const endMonth = dayjs(endTime).month()
                            const limitDay = (startMonth + 1 === 7 && endMonth + 1 === 8) ? 61 : 60
                            if (endTime - startTime > limitDay * 24 * 60 * 60 * 1000) {
                                ElMessage({
                                    message: '最多只能选择61天',
                                    type: 'warning'
                                })
                                item.value = []
                            }
                        }
                    }
                },
                detailRemarks: {
                    name: '发薪备注',
                    prop: 'detailRemarks',
                    type: 'input',
                    placeholder: '请输入发薪备注',
                    value: '',
                    defaultValue: ''
                }
            } as any,
            buttonList: [
                {
                    name: '停止发薪',
                    icon: 'export',
                    type: 'danger',
                    fn: () => {
                        this.stopPay()
                    }
                },
                {
                    name: '生成微信/支付宝回单',
                    icon: 'export',
                    plain: true,
                    fn: () => {
                        this.applyReceipt()
                    }
                },
                {
                    name: '下载微信/支付宝回单',
                    icon: 'export',
                    type: 'success',
                    plain: true,
                    fn: () => {
                        this.downloadReceiptOld()
                    }
                },
                {
                    name: '导出明细',
                    buttonType: 'export',
                    fn: () => {
                        this.onExport()
                    }
                }
            ] as any,
            tableHead: [
                {
                    prop: 'index',
                    fixed: 'left',
                    reserveSelection: false // 是否可以跨页
                },
                {
                    prop: 'userName',
                    label: '姓名'
                },
                {
                    prop: 'idCardNo',
                    label: '身份证号',
                    width: 170
                },
                {
                    prop: 'phone',
                    label: '手机号'
                },
                {
                    prop: 'projectName',
                    label: '所属项目',
                    width: 160
                },
                {
                    prop: 'serveCompanyName',
                    label: '所属企业',
                    width: 160
                },
                {
                    prop: 'payTime',
                    label: '发薪时间',
                    width: 160
                },
                {
                    prop: 'paymentAmount',
                    label: '发薪金额',
                    suffix: '元'
                },
                {
                    prop: 'auditStatus',
                    label: '审批状态'
                },
                {
                    prop: 'payAccountFlag',
                    label: '支付方式',
                    width: 160
                },
                {
                    prop: 'detailRemarks',
                    label: '发薪备注'
                },
                {
                    prop: 'statusMsg',
                    label: '领薪状态'
                },
                {
                    prop: 'getSalaryTime',
                    label: '领薪时间',
                    width: 160
                },
                {
                    prop: 'billStatus',
                    label: '回单状态'
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            tableData: [
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            pageInfo: {
                pageNum: 1,
                pageSize: 10,
                total: 0
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            approvalId: '',
            isApprovalPopup: false,
            selects: [] as any,
            userId: 0,
            accountExpireInfoStatus: 0,
            isBatchPay: false
        }
    },
    created() {
        user.waitLogin().then(() => {
            this.userId = user.userId
            this.accountExpireInfoStatus = user.accountExpireInfoStatus
            // this.getCompanyList()
            if (!this.$route?.query?.statusName) {
                this.getData()
            }
        })
    },
    methods: {
        async getCompanyList() {
            const result = await getCompanyList()
            this.searchList.serveCompanyName.options = result.list
        },
        getData() {
            const query = this.createQuery()
            payrollRecord(query).then(res => {
                this.tableData = res.list
                this.pageInfo.total = res.total
                this.$emit('total', res.total)
                if (this.selects?.length) {
                    this.$nextTick(() => {
                        this.selectDefault()
                    })
                }
            })
        },
        // 批量停止发薪
        async stopPay() {
            if (!this.selects.length) {
                this.$message.error('请至少选择一条数据')
                return
            }
            await stopPay(this.selects.join(','))
            ElMessage.success('停止发薪成功')
            await this.getData()
        },
        // 一键申请回单
        applyReceipt() {
            payrollRecordEle({
                ids: this.selects.join(','),
                ...this.createQuery(false)
            }).then(() => {
                ElMessage.success('申请成功')
                this.getData()
            })
        },
        // 下载申请回单
        async downloadReceiptOld() {
            if (!this.selects.length) {
                this.$message.error('请至少选择一条数据')
                return
            }
            const result = await downloadPayrollRecordEle({
                ids: this.selects.join(','),
                ...this.createQuery(false)
            })
            this.windowOpen()
            document.title = '正在下载中...'
            const loading = await ElLoading.service({
                fullscreen: true,
                text: '回单正在下载中，您可以在新界面继续操作'
            })
            const files = await axios({
                url:
                    'https://1403244436326619.cn-shanghai.fc.aliyuncs.com/2016-08-15/proxy/zip-service/zip-oss/',
                method: 'post',
                data: result
            })
            window.location.href = files.data
            await this.$message.success('导出成功！')
            await loading.close()
            closewin()
        },
        windowOpen() {
            window.open(
                window.location.pathname +
                '?tip#/pay'
            )
            const channel = new BroadcastChannel('new_tab_pay')
            const timer = setInterval(() => {
                const query = {
                    activeName: 'pay',
                    searchList: this.searchList,
                    pageInfo: this.pageInfo,
                    selects: this.selects.join(',')
                }
                channel.postMessage(JSON.stringify(query))
                console.log('postMessage')
            }, 50)
            channel.onmessage = e => {
                if (e.data === 'pay_close') {
                    clearInterval(timer)
                    channel.close()
                }
            }
        },
        // 导出
        async onExport() {
            const result = await payrollRecordExport({
                ids: this.selects.join(','),
                ...this.createQuery(false)
            }, {
                loading: true
            })
            if (!result.fileUrl && result.exportStatus === 0) {
                store.commit('getLongPollingFile', result)
            } else {
                axios({
                    url: result.fileUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then(async (response) => {
                    await downLoadFile(response, result.exportType, result.updateTime, result.remark)
                    await this.$message.success('导出成功！')
                })
            }
        },

        handleSelectionChange(row: any) {
            console.log(33333, row)
            const rows = uniqBy(row, 'id')
            console.log(44444, rows)
            this.selects = rows.map((item: any) => item.id)
            // const curSelect = row.map((item: any) => item.id)
            // this.selects = [...this.selects, ...curSelect]
        },
        createQuery(hasPage = true) {
            const query = {} as any
            if (hasPage) {
                query.pageNum = this.pageInfo.pageNum
                query.pageSize = this.pageInfo.pageSize
            }
            for (const key in this.searchList) {
                if (this.searchList[key].type === 'dateRange') {
                    query[`begin${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[0] : ''
                    query[`end${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[1] : ''
                } else {
                    query[(this.searchList[key]).prop] = this.searchList[key].value
                }
            }
            return query
        },
        handleSearch() {
            this.pageInfo.pageNum = 1
            this.getData()
        },
        handleSizeChange(ev: number) {
            this.pageInfo.pageSize = ev
            this.getData()
        },
        handleCurrentChange(ev: number) {
            this.pageInfo.pageNum = ev
            this.getData()
        },
        async setStatus(name: string, auditStatus = '') {
            if (!this.searchList.status.options?.length) {
                await this.searchList.status.handleFocus(this.searchList.status)
            }
            const cur = this.searchList.status.options.find((i: any) => i[this.searchList.status.defaultAttrs.label] === name)
            this.searchList.auditStatus.value = auditStatus ? Number(auditStatus) : ''
            if (cur) {
                this.searchList.status.value = cur[this.searchList.status.defaultAttrs.value]
                setTimeout(() => {
                    this.getData()
                }, 0)
            }
        },
        selectDefault() {
            setTimeout(() => {
                let selectRow = [] as any
                this.selects.forEach((i: any) => {
                    let cur = this.tableData.find((k: any) => k.id === i * 1)
                    if (cur) {
                        selectRow.push(cur)
                    }
                })
                if (selectRow.length) {
                    (this.$refs.table as any).$refs.elTable.clearSelection()
                    selectRow.forEach((i: any) => {
                        (this.$refs.table as any).$refs.elTable.toggleRowSelection(i)
                    })
                }
            }, 0)
        }
    }
})
