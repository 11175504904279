
import { defineComponent } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import dayjs from 'dayjs'
import searchComponent from '@/components/search-component/index.vue'
import tableComponent from '@/components/table-component/index.vue'
import store from '@/store/index'
import axios from 'axios'
import { dateFormat, confirm, downLoadFile } from '@/utils/util'
import batchPay from '@/components/batch-pay.vue'
import dialogApproval from './components/dialog-approval.vue'
import {
    getProcessOrder,
    ProcessOrderDto,
    getNotGetPeople,
    NotGetPeopleDto,
    screeningPeople,
    payScreenType,
    getApprovalProcess,
    approval
} from '@/api/pay.ts'

import {
    stopPay,
    batchApplyEle,
    batchDownloadEle,
    applyEle
} from '@/api/operation.ts'
import { exportProcessDetailOrderNew, exportProcessOrder } from '@/api/export'
import user from '@/utils/user'

import { statusOptions } from './options'
const firstDay = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
const lastDay = dayjs().endOf('month').format('YYYY-MM-DD')

export default defineComponent({
    name: 'SalaryAll',
    components: {
        searchComponent,
        tableComponent,
        batchPay,
        dialogApproval
    },
    data() {
        return {
            radio1: 1,
            radio2: 1,
            isUpexport: false,
            isUpapliy: false,
            isUpwait: false,
            searchList: {
                projectName: {
                    name: '项目名称',
                    prop: 'searchText',
                    type: 'input',
                    placeholder: '请输入项目名称',
                    value: '',
                    defaultValue: ''
                },
                serveCompanyName: {
                    name: '所属企业',
                    prop: 'serveCompanyName',
                    type: 'input',
                    placeholder: '请输入派遣单位',
                    value: '',
                    defaultValue: ''
                },
                remarks: {
                    name: '备注',
                    prop: 'remarks',
                    type: 'input',
                    placeholder: '请输入备注',
                    value: '',
                    defaultValue: ''
                },
                createUserName: {
                    name: '申请人',
                    prop: 'createUserName',
                    type: 'select',
                    placeholder: '请选择',
                    value: '',
                    defaultValue: '',
                    options: [],
                    defaultAttrs: {
                        label: 'label',
                        value: 'value'
                    },
                    handleFocus: (item: any) => {
                        if (item.options?.length) return
                        screeningPeople({}, { loading: true }).then(res => {
                            if (res?.length) {
                                item.options = res.map(i => {
                                    return {
                                        label: i,
                                        value: i
                                    }
                                })
                            }
                        })
                    }
                },
                applyDate: {
                    name: '申请时间',
                    prop: 'CreateTime',
                    type: 'dateRange',
                    startPlaceholder: '开始时间',
                    endPlaceholder: '结束时间',
                    value: []
                },
                payTime: {
                    name: '发薪时间',
                    prop: 'PayTime',
                    type: 'dateRange',
                    startPlaceholder: '开始时间',
                    endPlaceholder: '结束时间',
                    value: [],
                    handleChange: (item: any) => {
                        if (item.value?.length) {
                            const startTime = new Date(item.value[0]).getTime()
                            const endTime = new Date(item.value[1]).getTime()
                            const startMonth = dayjs(startTime).month()
                            const endMonth = dayjs(endTime).month()
                            const limitDay = (startMonth + 1 === 7 && endMonth + 1 === 8) ? 61 : 60
                            if (endTime - startTime > limitDay * 24 * 60 * 60 * 1000) {
                                ElMessage({
                                    message: '最多只能选择61天',
                                    type: 'warning'
                                })
                                item.value = []
                            }
                        }
                    }
                },
                status: {
                    name: '审核状态',
                    prop: 'state',
                    type: 'select',
                    placeholder: '请选择',
                    value: '',
                    defaultValue: '',
                    options: statusOptions,
                    defaultAttrs: {
                        label: 'label',
                        value: 'value'
                    }
                }
            } as any,
            buttonList: [
                {
                    name: '生成子弹回单',
                    icon: 'export',
                    plain: true,
                    fn: () => {
                        const list = this.searchList as any
                        const query = {
                            beginCreateTime: list.applyDate.value?.length ? list.applyDate.value[0] : '',
                            endCreateTime: list.applyDate.value?.length ? list.applyDate.value[1] : '',
                            searchText: list.projectName.value,
                            ids: (this.selects as any).join(','),
                            createUserName: list.createUserName.value
                        }
                        batchApplyEle(query).then(() => {
                            this.$message.success('批量申请成功！')
                            this.getData()
                        })
                    }
                },
                {
                    name: '下载子弹回单',
                    icon: 'export',
                    type: 'success',
                    plain: true,
                    fn: async () => {
                        if (this.selects.length === 0) {
                            this.$message.error('请至少选择一条')
                            return
                        }
                        const list = this.searchList as any
                        const query = {
                            beginCreateTime: list.applyDate.value?.length ? list.applyDate.value[0] : '',
                            endCreateTime: list.applyDate.value?.length ? list.applyDate.value[1] : '',
                            searchText: list.projectName.value,
                            ids: (this.selects as any).join(',')
                        }
                        const result = await batchDownloadEle(query)
                        window.location.href =
                            process.env.VUE_APP_BASE_URL +
                            '/common/downloadZip?delete=true&uuid=' +
                            result
                        this.$message.success('导出成功！')
                    }
                },
                {
                    name: '导出项目',
                    buttonType: 'export',
                    fn: async () => {
                        const query = this.createQuery(false)
                        query.ids = (this.selects as any).join(',')
                        const result = await exportProcessOrder(query, {
                            loading: true
                        })
                        if (!result.fileUrl && result.exportStatus === 0) {
                            store.commit('getLongPollingFile', result)
                        } else {
                            axios({
                                url: result.fileUrl,
                                method: 'GET',
                                responseType: 'blob'
                            }).then(async (response: any) => {
                                await downLoadFile(response, result.exportType, result.updateTime, result.remark)
                                await this.$message.success('导出成功！')
                            })
                        }
                        console.log(result, 'result')
                    }
                },
                {
                    name: '导出明细',
                    buttonType: 'export',
                    fn: () => {
                        this.handleExportDetail(this.selects)
                    }
                },
                {
                    name: '批量发薪',
                    icon: 'export',
                    type: 'success',
                    disabled: false,
                    fn: () => {
                        this.showPayDialog()
                    }
                }
            ] as any,
            tableHead: [
                {
                    prop: 'index'
                },
                {
                    prop: 'projectName',
                    label: '项目名称'
                },
                {
                    prop: 'serveCompanyName',
                    label: '所属企业'
                },
                {
                    prop: 'peopleNum',
                    label: '发薪人数'
                },
                {
                    prop: 'payRealHairTotalMoney',
                    label: '实发金额',
                    suffix: '元'
                },
                {
                    prop: 'payStopTotalMoney',
                    label: '停发金额',
                    suffix: '元'

                },
                {
                    prop: 'createTime',
                    label: '申请时间',
                    width: 160
                },
                {
                    prop: 'remarks',
                    label: '发薪备注'
                },
                {
                    prop: 'createUserName',
                    label: '申请人'
                },
                {
                    prop: 'status',
                    label: '审批状态'
                },
                {
                    prop: 'approvalTime',
                    label: '发薪时间',
                    width: 160
                },
                {
                    prop: 'payAccountFlag',
                    label: '支付方式',
                    width: 160
                },
                {
                    prop: 'operate',
                    label: '操作',
                    width: 160,
                    fixed: 'right'
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            tableData: [
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            pageInfo: {
                pageNum: 1,
                pageSize: 10,
                total: 0
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            approvalId: '',
            isApprovalPopup: false,
            selects: [],
            userId: 0,
            accountExpireInfoStatus: 0,
            isBatchPay: false
        }
    },
    created() {
        user.waitLogin().then(() => {
            this.userId = user.userId
            this.accountExpireInfoStatus = user.accountExpireInfoStatus
            const cur = this.buttonList.find((i: any) => i.name === '批量发薪')
            cur.disabled = this.accountExpireInfoStatus === 3
        })
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            const query = this.createQuery()
            getProcessOrder(query).then(res => {
                this.tableData = res.list
                this.pageInfo.total = res.total
                this.$emit('total', res.total)
            })
        },
        async onIsApprovalPopup(row: any) {
            if (!row.isShow) {
                this.$message.error('当前审批单无需您审批')
                return
            }
            this.approvalId = row.id
            this.isApprovalPopup = true
        },

        handleSelectionChange(row: any) {
            console.log(row)
            this.selects = row.map((item: any) => item.id)
        },
        createQuery(hasPage = true) {
            const query = {} as any

            if (hasPage) {
                query.pageNum = this.pageInfo.pageNum
                query.pageSize = this.pageInfo.pageSize
            }

            for (const key in this.searchList) {
                if (this.searchList[key].type === 'dateRange') {
                    query[`begin${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[0] : ''
                    query[`end${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[1] : ''
                } else {
                    query[(this.searchList[key]).prop] = this.searchList[key].value
                }
            }
            return query
        },
        handleSearch() {
            console.log(1)
            this.pageInfo.pageNum = 1
            this.getData()
        },
        handleSizeChange(ev: number) {
            console.log(2)
            this.pageInfo.pageSize = ev
            this.getData()
        },
        handleCurrentChange(ev: number) {
            console.log(3)
            this.pageInfo.pageNum = ev
            this.getData()
        },
        handleArchive(row: any) {
            this.$router.push({
                path: '/pay/detail',
                query: {
                    id: btoa(row.id)
                }
            })
        },
        showPayDialog() {
            this.isBatchPay = true
        },
        // 申请回单
        async onApplyEle(id: string) {
            await applyEle(id)
            this.$message.success('回单申请成功！')
            this.getData()
        },
        // 导出回单
        openUrl(url: string) {
            window.location.href = url
            this.$message.success('导出成功！')
        },
        doExport(id: string) {
            this.handleExportDetail([id])
        },
        // 导出明细
        async handleExportDetail(ids: any) {
            // if (!this.searchList.payTime?.value?.length && !ids?.length) {
            //   this.$message.error('请选择发薪时间，时间最大范围为两个月')
            //   return
            // }
            const result = await exportProcessDetailOrderNew(
                {
                    processIds: ids.join(','),
                    beginPayTime: this.searchList.payTime.value?.length ? this.searchList.payTime.value[0] : '',
                    endPayTime: this.searchList.payTime.value?.length ? this.searchList.payTime.value[1] : '',
                    state: this.searchList.status.value ?? '',
                    ...this.createQuery(false)
                },
                {
                    loading: true
                }
            )
            console.log(result, '导出信息')
            if (!result.fileUrl && result.exportStatus === 0) {
                store.commit('getLongPollingFile', result)
            } else {
                axios({
                    url: result.fileUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then(async (response) => {
                    await downLoadFile(response, result.exportType, result.updateTime, result.remark)
                    await this.$message.success('导出成功！')
                })
            }
        }
    }
})
