<template>
    <el-card>
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane label="发薪项目" name="project">
                <salaryAll @total="handleStart" />
            </el-tab-pane>
            <el-tab-pane label="人员账单明细" name="pay">
                <payAll ref="payAll" />
            </el-tab-pane>
            <el-tab-pane label="人员账单汇总" name="third">
                <billSumList />
            </el-tab-pane>
        </el-tabs>
    </el-card>
</template>
<script>
import 'shepherd.js/dist/css/shepherd.css'
import { defineComponent } from 'vue'
import salaryAll from './salary-all.vue'
import payAll from './pay-all.vue'
import billSumList from './bill-sum-list.vue'
import { ElMessageBox } from 'element-plus'
import router from '@/router'
import { useShepherd } from 'vue-shepherd'
import { offset } from '@floating-ui/dom'
export default defineComponent({
    name: 'Pay',
    components: {
        salaryAll,
        payAll,
        billSumList
    },
    data() {
        return {
            activeName: 'project',
            tour: null
        }
    },
    beforeMount() {
        // 被跳转到该页面恢复原页面数据
        if (/tip/.test(router.options.history.base)) {
            ElMessageBox.alert('回单正在下载中，您可以在该界面继续操作')
            const channel = new BroadcastChannel('new_tab_pay')
            let isLoad = 0
            channel.onmessage = e => {
                const data = JSON.parse(e.data)
                this.activeName = data.activeName
                isLoad = 1
                channel.postMessage('pay_close')
                channel.close()
                console.log(9999, data)
                this.$refs.payAll.searchList = data.searchList
                this.$refs.payAll.pageInfo = data.pageInfo
                this.$refs.payAll.selects = data.selects.split(',')
                this.$refs.payAll.getData()
            }
            setTimeout(() => {
                if (!isLoad) {
                    window.location.search = ''
                }
                channel.close()
            }, 5000)
        }
    },
    mounted() {
        if (this.$route?.query?.type) {
            this.activeName = this.$route.query.type
        }
        if (this.$route?.query?.statusName) {
            this.$refs.payAll.setStatus(this.$route?.query?.statusName, (this.$route?.query?.auditStatus || ''))
        }
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event)
        },
        createStep() {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            let _this = this
            setTimeout(() => {
                this.tour = useShepherd({
                    useModalOverlay: true,
                    keyboardNavigation: true,
                    defaultStepOptions: {
                        // cancelIcon: {
                        //     enabled: true
                        // },
                        scrollTo: { behavior: 'smooth', block: 'center' },
                        // 高亮元素四周要填充的空白像素
                        modalOverlayOpeningPadding: 8,
                        // 空白像素的圆角
                        modalOverlayOpeningRadius: 4,
                        buttons: [{
                            action() {
                                return this.back()
                            },
                            text: '上一步'
                        }, {
                            action() {
                                return this.next()
                            },
                            text: '下一步'
                        }]
                    }
                })
                this.tour.addStep({
                    attachTo: { element: document.querySelector('.el-menu-item.is-active'), on: 'bottom' },
                    text: '原“发薪、领薪记录、个人领薪记录”等都移到了“发薪记录”里，可点击“发薪-发薪记录”查看',
                    classes: 'example-step-extra-class',
                    floatingUIOptions: {
                        middleware: [offset({ mainAxis: 30, crossAxis: 12 })]
                    },
                    buttons: [
                        {
                            text: '跳过',
                            classes: 'shepherd-btn1',
                            action() {
                                _this.handleFinish()
                                return this.complete()
                            }
                        },
                        {
                            text: '下一步1/6',
                            classes: 'shepherd-btn2',
                            action() {
                                return this.next()
                            }
                        }
                    ]
                })
                this.tour.addStep({
                    attachTo: { element: document.querySelector('#tab-project'), on: 'bottom' },
                    text: '您可以在这里查看项目，导出项目明细、生成/下载子弹回单、导出项目名单、批量发薪',
                    classes: 'example-step-extra-class',
                    floatingUIOptions: {
                        middleware: [offset({ mainAxis: 30, crossAxis: 12 })]
                    },
                    buttons: [
                        {
                            text: '跳过',
                            classes: 'shepherd-btn1',
                            action() {
                                _this.handleFinish()
                                return this.complete()
                            }
                        },
                        {
                            text: '下一步2/6',
                            classes: 'shepherd-btn2',
                            action() {
                                return this.next()
                            }
                        }
                    ]
                })
                console.log(document.querySelectorAll('.tep-3')[1])
                this.tour.addStep({
                    attachTo: { element: document.querySelectorAll('.tep-3')[1], on: 'bottom' },
                    text: '您可以在这里查看发薪项目详情，在详情内页中可导出微信回单',
                    classes: 'example-step-extra-class',
                    floatingUIOptions: {
                        middleware: [offset({ mainAxis: 30, crossAxis: 12 })]
                    },
                    buttons: [
                        {
                            text: '跳过',
                            classes: 'shepherd-btn1',
                            action() {
                                _this.handleFinish()
                                return this.complete()
                            }
                        },
                        {
                            text: '下一步3/6',
                            classes: 'shepherd-btn2',
                            action() {
                                _this.activeName = 'pay'
                                return this.next()
                            }
                        }
                    ]
                })
                this.tour.addStep({
                    attachTo: { element: document.querySelector('#tab-pay'), on: 'bottom' },
                    text: '您可以在这里查看/导出人员账单明细、生成/下载微信回单、停止发薪。如想单独查看某个员工账单，可搜索员工信息后，进行查看操作。',
                    classes: 'example-step-extra-class',
                    floatingUIOptions: {
                        middleware: [offset({ mainAxis: 30, crossAxis: 12 })]
                    },
                    buttons: [
                        {
                            text: '跳过',
                            classes: 'shepherd-btn1',
                            action() {
                                _this.handleFinish()
                                return this.complete()
                            }
                        },
                        {
                            text: '下一步4/6',
                            classes: 'shepherd-btn2',
                            action() {
                                _this.activeName = 'third'
                                return this.next()
                            }
                        }
                    ]
                })
                this.tour.addStep({
                    attachTo: { element: document.querySelector('#tab-third'), on: 'bottom' },
                    text: '原“个人领薪记录”迁移到这里，可在此查看人员发薪记录汇总。',
                    classes: 'example-step-extra-class',
                    floatingUIOptions: {
                        middleware: [offset({ mainAxis: 30, crossAxis: 12 })]
                    },
                    buttons: [
                        {
                            text: '跳过',
                            classes: 'shepherd-btn1',
                            action() {
                                _this.handleFinish()
                                return this.complete()
                            }
                        },
                        {
                            text: '下一步5/6',
                            classes: 'shepherd-btn2',
                            action() {
                                return this.next()
                            }
                        }
                    ]
                })
                this.tour.addStep({
                    attachTo: { element: document.querySelectorAll('.tep-6')[1], on: 'bottom' },
                    text: '可在“详情”中查看员工当月发薪明细、全部月份统计记录。',
                    classes: 'example-step-extra-class',
                    floatingUIOptions: {
                        middleware: [offset({ mainAxis: 30, crossAxis: 12 })]
                    },
                    buttons: [
                        {
                            text: '跳过',
                            classes: 'shepherd-btn1',
                            action() {
                                _this.handleFinish()
                                return this.complete()
                            }
                        },
                        {
                            text: '完成6/6',
                            classes: 'shepherd-btn2',
                            action() {
                                _this.activeName = 'project'
                                _this.handleFinish()
                                return this.complete()
                            }
                        }
                    ]
                })
                this.tour.start()
            }, 100)
        },
        handleFinish() {
            this.activeName = 'project'
            localStorage.setItem('guide-finish', true)
        },
        handleStart() {
            // 如果没有完成指导开始做指导操作
            if (!localStorage.getItem('guide-finish')) {
                this.createStep()
            }
        }
    }
})
</script>
<style>
.shepherd-content {
    background: #1989FA;
}

.shepherd-text {
    color: #fff;
}

.shepherd-btn1 {
    border: 1px solid #fff;
    background: #1989FA;
    color: #fff;
}

.shepherd-btn2 {
    border: 1px solid #1989FA;
    background: #fff;
    color: #1989FA;
}

.shepherd-arrow:before {
    background-color: #1989FA;
}
</style>
