
import { defineComponent } from 'vue'
import alertBox from '@/components/alert-box.vue'
import { dateFormat, confirm, downLoadFile } from '@/utils/util'
import {
  approval, getApprovalProcess
} from '@/api/pay.ts'

export default defineComponent({
  components: {
    alertBox
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    approvalId: {
      type: [Number, String],
      default: ''
    },
    userId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      isApprovalPopup: false,
      approvalInfo: {} as any,
      approvalRadio: 0,
      textArea: ''
    }
  },
  watch: {
    show(newval) {
      this.isApprovalPopup = newval
      if (newval) {
        this.getApprovalProcess()
      }
    },
    isApprovalPopup(newval) {
      this.$emit('update:show', newval)
    }
  },
  methods: {
    // 获取审批流列表
    async getApprovalProcess() {
      const result = await getApprovalProcess({
        id: Number(this.approvalId)
      })
      this.approvalInfo = result
    },
    // 关闭审批流弹窗
    onClose() {
      this.isApprovalPopup = false
    },
    onCloseApproval() {
      this.onClose()
      this.approvalRadio = 0
    },
    // 审批
    async onApproval() {
      console.log(this.textArea, 'textAreatextArea')
      await approval({
        serveCompanyId: this.approvalInfo.serveCompanyId,
        id: Number(this.approvalId),
        params: {
          isAgree: this.approvalRadio,
          auditContent: this.textArea || (Number(this.approvalRadio) === 1 ? '同意' : Number(this.approvalRadio) === 2 ? '驳回' : '')
        }
      })
      console.log(this.approvalRadio, '点击啦')
    },
    // 取消申请
    onCancelApply() {
      confirm('', '确认取消审批吗').then(async () => {
        await this.onApproval()
        this.textArea = ''
        this.$message.success('取消审批成功')
        this.$emit('doSearch')
        this.isApprovalPopup = false
      })
    },
    async onDefineApproval() {
      if (!this.approvalRadio) {
        this.$message.error('请至少选择一个')
        return
      }
      if (Number(this.approvalRadio) === 2 && !this.textArea) {
        this.$message.error('请输入驳回原因')
      }
      await this.onApproval()
      this.textArea = ''
      this.$emit('doSearch')
      this.isApprovalPopup = false
      if (Number(this.approvalRadio) === 1) {
        this.$message.success('审批成功')
      } else {
        this.$message.success('驳回成功')
      }
    }
  }
})
